import {Popover, Transition} from "@headlessui/react";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import {CheckCircleIcon, ClockIcon, MapPinIcon, PhoneIcon,} from "@heroicons/react/24/outline";
import {Fragment, useEffect, useRef, useState} from "react";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {RingLoader} from "react-spinners";
import cardIcon from "../assets/images/card-logo.png";
import mbwayIcon from "../assets/images/mbway.png";
import {Order} from "../models/order";
import {OrderItem} from "../models/order-item";
import addressService from "../services/address.service";
import easypayService from "../services/easypay.service";
import myposService from "../services/mypos.service";
import scuverService from "../services/scuver.service";
import store from "../state-store";
import orderHelper from "../utils/order-helper";
import {MyMoment, MyTime} from "../utils/time-helper";
import AddressSelection from "../components/AddressSelection";
import Button from "../components/Button";
import Dialog from "../components/Dialog";
import Image from "../components/Image";
import Select from "../components/Select";
import shopHelper from "../utils/shop-helper.ts";
import orderService from "../services/order.service.ts";
import Header from "../components/Header.tsx";

export default function OrderPage() {
  const [order, setOrder] = useState<Order>(null);
  const [displayAddresses, setDisplayAddresses] = useState(false);
  const [useSavedCard, setUseSavedCard] = useState(false);
  const [displayPaymentText, setDisplayPaymentText] = useState("");
  const [minTime, setMinTime] = useState(MyTime.parse("08:00"));
  const [maxTime, setMaxTime] = useState(MyTime.parse("23:00"));
  const {t} = useTranslation();
  const navigate = useNavigate();
  const timeRef = useRef(null);
  // let interval = null;

  useEffect(() => {
    const pathio = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1,
    );
    if (pathio && pathio !== "order") {
      orderService.getOrder(pathio).then((o) => {
        if (o && o.uid) {
          orderService.setCurrentOrder(o);
        }
      });
    }
    orderService.observeCurrentOrder().subscribe((o) => {
      console.log("order in order page", o);
      if (o && o.status === "being-created") {
        if (
          (o && o.address?.addressLine1 !== order?.address?.addressLine1) ||
          (o && o.address?.local !== order?.address?.local)
        ) {
          recalculate(o).then((ord) => setOrder(ord));
        } else {
          setOrder(o);
        }
      } else if (o) {
        navigate("/order-status/" + o.uid);
      }
    });
    // if (!interval) {
    //   interval = setInterval(() => {
    //     recalculate(o).then(ord => setOrder(ord))
    //   }, 60000);
    // }
    timeRef.current.blur();
  }, []);

  useEffect(() => {
    // addressService.observeCurrentAddress().subscribe(async a => {
    //   if (a && a.addressLine1 && a.addressLine1 !== order?.address?.addressLine1) {
    //     console.log('address changed', a)
    //     setOrder(await recalculate({...order, address: a}))
    //   }
    // })
    if (order && order.status === 'pending') {
      navigate("/order-status/" + order.uid);
    }
  }, [order])

  async function recalculate(o: Order) {
    console.log("recalculate", o);
    if (o && o?.shop) {
      const kms = await addressService.getRoadDistanceInKm(
        o.shop?.address.coordinates,
        o?.address?.coordinates,
      );
      if (kms > 15) {
        toast.error(
          t(
            "We do not deliver from this restaurant to your address. Please choose another restaurant.",
          ),
        );
        toast.error(
          t(
            "Lamentamos mas não entregamos nesta morada. Por favor escolha outro restaurante.",
          ),
        );
        setOrder({...order, address: null});
      } else if (kms !== o.distanceInKm) {
        const deliveryFee =
          kms * (o.shop.deliveryFeePerKm || 0.55) +
          (o.shop.deliveryFeeMin ? o.shop.deliveryFeeMin : 1.3);
        console.log("setDeliveryFee deliveryFee", deliveryFee);
        o = {
          ...o,
          distanceInKm: kms,
          deliveryFee,
          total: o?.subTotal + deliveryFee,
          arrivalExpectedAt: shopHelper
            .getNextOrderTime(o.shop, kms)
            .toString(),
        };
      }
      if (kms < 0) {
        setMinTime(shopHelper.getNextOrderTime(o.shop, kms).time);
        setMaxTime(
          shopHelper
            .latestArrivalTime(o.shop)
            ?.isWithinTimeSpan(MyTime.getCurrentTime(), MyTime.parse("23:59"))
            ? shopHelper.latestArrivalTime(o.shop)
            : MyTime.parse("22:30"),
        );
      }
    }
    return o;
  }

  function changeAddress() {
    setDisplayAddresses(true);
    store.setState('lastDiagloKey', 'address');
  }

  const paymentMethods = [
    ...(order?.user?.cardToken
      ? [
        {
          value: "card",
          label: t("Previous Online Card"),
          iconImage: cardIcon,
        },
      ]
      : []),
    {
      value: "mbw",
      label: t("MBWay"),
      iconImage: mbwayIcon,
    },
    {
      value: "card",
      label: t("Card Online"),
      iconImage: cardIcon,
    },
    {
      value: "payment-on-delivery",
      label: t("Card on Delivery"),
      iconImage: cardIcon,
    },
  ];

  const quantityChanged = (orderItem: OrderItem, quantity) => {
    const orderItems = JSON.parse(JSON.stringify(order.orderItems));
    orderItems.find(
      (storedOrderItem) =>
        JSON.stringify(storedOrderItem) === JSON.stringify(orderItem),
    ).quantity = Number(quantity);
    const updatedOrder = {...order, orderItems};
    orderService.setCurrentOrder({
      ...updatedOrder,
      subTotal: orderHelper.calculateSubTotal(updatedOrder),
      total: orderHelper.calculateOrderTotal(updatedOrder),
    });
  };

  const setPaymentMethod = (m) => {
    const paymentMethod = m.value;
    if (m.label === t("Previous Online Card")) {
      setUseSavedCard(true);
    } else {
      setUseSavedCard(false);
    }
    orderService.setCurrentOrder({...order, paymentMethod});
  };

  const setNotes = (notes) => {
    // orderService.setCurrentOrder({ ...order, notes });
    setOrder({...order, notes});
  };

  const setPhoneNumber = (phoneNumber) => {
    console.log("setPhoneNumber", phoneNumber);
    if (phoneNumber) {
      setOrder({...order, user: {...order.user, phoneNumber}});
      store.setState("phoneNumber", phoneNumber);
    }
  };

  const deleteOrder = () => {
    orderService.removeOrder(order.uid);
    window.location.href = "/";
  };

  const removeOrderItem = (orderItem: OrderItem) => {
    const orderItems = JSON.parse(JSON.stringify(order.orderItems));
    orderItems.splice(
      orderItems.findIndex(
        (storedOrderItem) =>
          JSON.stringify(storedOrderItem) === JSON.stringify(orderItem),
      ),
      1,
    );
    orderService.setCurrentOrder({...order, orderItems});
  };

  async function submitOrder() {
    const phoneNumber: string = store.getState("phoneNumber");
    if (!order?.user?.phoneNumber && !phoneNumber) {
      toast.error(t("Please provide a Phone Number"));
      return;
    } else if (phoneNumber && !order?.user?.phoneNumber) {
      scuverService.addOrUpdateRecord("users", {...order.user, phoneNumber});
    }
    if (!order.deliveryFee || !order.distanceInKm) {
      toast.error(
        t("Forneça um endereço de entrega válido. Please provide an address"),
      );
      return;
    }
    const nextOrderTime = shopHelper.getNextOrderTime(
      order.shop,
      order.distanceInKm,
    );
    const fullOrder = {
      ...order,
      phoneNumber,
      arrivalExpectedAt: order.arrivalExpectedAt || nextOrderTime.toString(),
      arrivalEstimatedAt: order.arrivalExpectedAt || nextOrderTime.toString(),
      isScheduled: MyMoment.parse(order.arrivalExpectedAt).time.isAfter(
        minTime,
      ),
      fcmToken: localStorage.getItem("firebase_token"),
      subTotal: orderHelper.calculateSubTotal(order),
      total: orderHelper.calculateOrderTotal(order),
      submittedAt: MyMoment.getCurrentMoment().toString(),
      isTastic: true,
    };
    const localStorageOrders = localStorage.getItem("orders");
    if (localStorageOrders) {
      const orders = JSON.parse(localStorageOrders);
      orders.push(fullOrder);
      localStorage.setItem("orders", JSON.stringify(orders));
    }
    // scuverService.observeRecord('orders', fullOrder.uid).subscribe(observedOrder => {
    //   console.log('observedOrder', observedOrder);
    //   if (observedOrder.status === 'pending') {
    //     orderService.setCurrentOrder(observedOrder);
    //     setDisplayPaymentText('');
    //     navigate('/order-status/'+observedOrder.uid);
    //   }
    // });
    // scuverService.observeRecord('orders', fullOrder.uid).pipe(first()).subscribe(observedOrder => {
    //   if (fullOrder.status === 'being-created' && observedOrder.status === 'pending') {
    //     toast.success(t('Your order has been submitted successfully'));
    //     navigate('/order-status/'+observedOrder.uid);
    //   }
    // });
    if (fullOrder?.paymentMethod === "payment-on-delivery") {
      await orderService.updateOrder({...fullOrder, status: "pending"});
    } else if (fullOrder?.paymentMethod === "card" && useSavedCard) {
      myposService
        .payByToken(fullOrder, fullOrder.user.cardToken)
        .then(() => {
          orderService.updateOrder({
            ...fullOrder,
            status: "pending",
            paid: true,
          });
        })
        .catch((err) => toast.error(err.message || err.error || err));
    } else if (fullOrder?.paymentMethod === "card" && !useSavedCard) {
      setDisplayPaymentText(
        t(
          "Please use MyPos page to proceed with payment. Then return to this page.",
        ),
      );
      await orderService.updateOrder({...fullOrder});
      myposService.pay(fullOrder?.total, fullOrder.uid, fullOrder?.user?.uid);
    } else if (fullOrder?.paymentMethod === "mbw") {
      await orderService.updateOrder({...fullOrder});
      easypayService
        .createEasypayPayment(
          fullOrder.user,
          fullOrder.uid,
          fullOrder.total,
          "mbw",
          "createPayment",
          "Order",
        )
        .then(() => {
          setDisplayPaymentText(
            t("Please accept payment on MBWay and return to this page."),
          );
        })
        .catch((err) => toast.error(err.message || err.error || err));
    }

    scuverService.addOrUpdateRecord("users", {...fullOrder.user});
  }

  return (
    <div className={"min-h-screen"}>
      <Header/>
      <main className="flex flex-col text-gray-800 w-[96vw] p-2 md:max-w-5xl md:p-8">
        <div className="pt-0">
          <h1 className="text-sm md:text-xl text-center md:text-left font-bold tracking-tight text-gray-900">
            {t("Your Order")} - {order?.shop?.name}
          </h1>

          <form className="mt-4 overflow-auto">
            {/* Order Items */}
            <section aria-labelledby="cart-heading">
              <ul
                role="list"
                className="divide-y divide-gray-200 border-b border-t border-gray-200"
              >
                {order?.orderItems?.map((orderItem) => (
                  <li key={orderItem.uid} className="flex py-4">
                    <div className="flex-shrink-0 w-[5em] hidden md:flex">
                      <Image
                        src={orderItem.photoUrl}
                        classes="border border-[#ccc] w-full h-20 rounded-2xl object-cover w-32"
                      />
                    </div>
                    <div className="flex flex-col justify-between ml-4 w-full ">
                      <div>
                        <div className="flex justify-between">
                          <div className="pr-6">
                            <h3 className="text-sm">
                              <a
                                href={"#"}
                                className="font-medium text-gray-700 hover:text-gray-800"
                              >
                                {orderItem.name}
                              </a>
                            </h3>
                            <p className="md:block mt-1 text-sm text-gray-500">
                              {orderItem.optionsSelected?.map((option, itt) => (
                                <span key={"orderItem_" + itt + "_" + itt}>
                                  {option.quantity > 1 ? (option.quantity + 'x') : ''} {option.name}{" "}
                                  {itt < orderItem.optionsSelected.length - 1 ? " - " : ""}
                                </span>
                              ))}
                            </p>
                          </div>
                          <div className="flex items-start">
                            <div className="flex flex-col justify-start">
                              <Select
                                initialValue={orderItem.quantity}
                                options={[
                                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 30, 50,
                                ]}
                                onChange={(v) => quantityChanged(orderItem, v)}
                              />
                              <Popover className="relative">
                                {({open}) => (
                                  <>
                                    <Popover.Button
                                      className={`
                                        ${open ? "" : "text-opacity-90"}
                                        group cursor-pointer inline-flex items-center rounded-md bg-transparent px-3 py-2 text-sm font-medium text-primary-600 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                    >
                                      <span>{t("Remove")}</span>
                                    </Popover.Button>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-200"
                                      enterFrom="opacity-0 translate-y-1"
                                      enterTo="opacity-100 translate-y-0"
                                      leave="transition ease-in duration-150"
                                      leaveFrom="opacity-100 translate-y-0"
                                      leaveTo="opacity-0 translate-y-1"
                                    >
                                      <Popover.Panel
                                        className="absolute z-50 mt-1 -translate-x-32 md:-translate-x-40 transform">
                                        <div
                                          className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white p-4">
                                          <span className="text-gray-800">
                                            {t(
                                              "Are you sure you want to remove this item?",
                                            )}
                                          </span>
                                          <div className="flex px-10 mt-4">
                                            <Button
                                              classes="cursor-pointer w-24"
                                              onClick={() =>
                                                removeOrderItem(orderItem)
                                              }
                                              text={t("Remove")}
                                            />
                                          </div>
                                        </div>
                                      </Popover.Panel>
                                    </Transition>
                                  </>
                                )}
                              </Popover>
                            </div>
                            <p className="text-right text-sm font-medium text-gray-900 ml-4">
                              €
                              {orderHelper
                                .getOrderItemTotal(orderItem)
                                .toFixed(2)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </section>
            {/* Payment and Totals */}
            <section className="flex flex-col md:flex-row md:justify-between md:space-x-4">
              <div className="rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:p-8 md:w-6/12 mt-4">
                <h2 id="summary-heading" className="sr-only">
                  {t("Order Summary")}
                </h2>
                <div className="flow-root">
                  <dl className="-my-4 divide-y divide-gray-200 text-sm">
                    <div className="flex items-center justify-between py-4">
                      <dt className="text-gray-600">{t("Subtotal")}</dt>
                      <dd className="font-medium text-gray-900">
                        €{order?.subTotal?.toFixed(2)}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between py-4">
                      <dt className="text-gray-600">{t("Delivery")}</dt>
                      <dd className="font-medium text-gray-900">
                        €{order?.deliveryFee?.toFixed(2)}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between py-4">
                      <dt className="text-base font-medium text-gray-900">
                        {t("Order total")}
                      </dt>
                      <dd className="text-base font-medium text-gray-900">
                        €{order?.total?.toFixed(2)}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="flex flex-col md:w-6/12 mt-2 rounded-lg border-gray-400 bg-gray-50 text-gray-800 p-4">
                {order &&
                  paymentMethods.map((m, it) => (
                    <div
                      key={`${m.value}_${it}`}
                      className={`cursor-pointer flex justify-between shadow p-2 items-center border-gray-400 ${m.value === order?.paymentMethod ? "bg-gray-100 ring-1 ring-white ring-opacity-100 ring-offset-2 ring-offset-black-300" : ""}`}
                      onClick={() => setPaymentMethod(m)}
                    >
                      <div className="flex items-center">
                        <img src={m.iconImage} className="w-8"/>
                        <span className="uppercase text-sm ml-4">
                          {m.label}
                        </span>
                      </div>
                      <div className="justify-end w-6">
                        {m.value === order?.paymentMethod ? (
                          <CheckCircleIcon className="w-6" color="#27b787"/>
                        ) : (
                          <ChevronRightIcon className="w-6"/>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </section>
            {/* Hour, Address, Phone and Notes */}
            <section className="flex flex-col md:flex-row md:justify-between md:space-x-4">
              <div className="w-full md:w-6/12">
                <div className="flex w-full mt-2 rounded-lg border-gray-400 bg-gray-50 text-gray-800 p-4">
                  <div
                    className={
                      "flex w-full text-gray-800 bg-transparent self-center rounded-full text-center justify-between items-center cursor-pointer"
                    }
                  >
                    <ClockIcon className="w-10"/>
                    <div className="flex flex-col">
                      <span className="text-xs md:text-base">
                        {t("Deliver At")}
                      </span>
                      <div className={"w-[10em] pl-10"}>
                        <input
                          ref={timeRef}
                          min={minTime?.toString()}
                          max={maxTime?.toString()}
                          className={
                            "font-bold text-xl md:text-2xl border-none bg-transparent"
                          }
                          type={"time"}
                          autoFocus={false}
                          tabIndex={9999}
                          onChange={(t) => {
                            console.log("t.target.value", t.target.value);
                            const time = MyTime.parse(t.target.value);
                            if (time.isWithinTimeSpan(minTime, maxTime)) {
                              setOrder({
                                ...order,
                                arrivalExpectedAt: MyMoment.todayAt(
                                  MyTime.parse(t.target.value),
                                ).toString(),
                              });
                            } else {
                              setOrder({
                                ...order,
                                arrivalExpectedAt:
                                  MyMoment.todayAt(minTime).toString(),
                              });
                            }
                          }}
                          value={
                            order?.arrivalExpectedAt
                              ? MyMoment.parse(
                                order.arrivalExpectedAt,
                              ).time.toString()
                              : minTime.toString()
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="justify-end ml-2"
                      onClick={(e) => {
                        e.preventDefault();
                        timeRef.current.focus();
                      }}
                    >
                      <button
                        className={
                          "bg-black text-white rounded-lg text-xs p-1 font-bold cursor-pointer"
                        }
                      >
                        {t("Change")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex w-full mt-2 rounded-lg border-gray-400 bg-gray-50 text-gray-800 p-4">
                  <div
                    className={
                      "flex text-gray-800 bg-transparent self-center rounded-full text-center justify-between space-x-2 items-center cursor-pointer"
                    }
                    onClick={() => changeAddress()}
                  >
                    <MapPinIcon className="w-10"/>
                    <div className="flex flex-col">
                      {/*<span className='text-xs md:text-base'>{t('Address')}</span>*/}
                      <span
                        id="order-address"
                        className={"font-bold text-xs md:text-sm"}
                      >
                        {order?.address
                          ? `${order?.address.addressLine1} ${order?.address.addressLine2 || ""} ${order?.address.postCode || ""} ${order?.address.local || ""}`
                          : t("Address")}
                      </span>
                    </div>
                    <div className="justify-end w-6">
                      <ChevronRightIcon className="w-6 h-6"/>
                    </div>
                  </div>
                </div>
                <div
                  className="flex flex-col self-start w-full mt-2 rounded-lg border-gray-400 bg-gray-50 text-gray-800 p-4">
                  <span className="text-sm">
                    {t("Phone Number")}
                    <span className="text-[#e64444] text-lg"> *</span>
                  </span>
                  <div className="flex w-full">
                    <PhoneIcon className="w-8 mr-4 mt-2"/>
                    <input
                      id="phone"
                      className="p-2 w-10/12 rounded border-1 border border-gray-300 mt-2 focus:border-transparent focus:ring-0 focus:outline-0 h-full"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={order?.user?.phoneNumber || ""}
                    />
                  </div>
                </div>
              </div>
              <div
                className="w-full md:w-6/12 flex flex-col mt-2 rounded-lg border-gray-400 bg-gray-50 text-gray-800 p-4">
                <span className="text-sm">{t("Notes")}</span>
                <textarea
                  className="rounded border border-gray-300 mt-2 focus:ring-0 h-full"
                  onChange={(e) => setNotes(e.target.value)}
                  value={order?.notes}
                ></textarea>
              </div>
            </section>
            {/* Buttons */}
            <section className="mt-2">
              <div className="mt-6 md:mt-10">
                <button
                  type="button"
                  onClick={() => submitOrder()}
                  className="w-full cursor-pointer rounded-md border border-transparent bg-primary-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                  {t("Checkout")}
                </button>
              </div>

              <div className="mt-2 md:mt-4 text-center text-sm text-gray-500">
                <p>
                  {t("or")}&nbsp;
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/shop/${order?.shop?.uid}`);
                    }}
                    className="font-medium text-primary-600 hover:text-primary-500"
                  >
                    {t("Continue Shopping")}
                    <span aria-hidden="true"> &rarr;</span>
                    <button
                      type="button"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Tem a certeza que quer apagar o seu pedido?" +
                            "\n" +
                            "Are you sure you want to delete your order?",
                          )
                        ) {
                          deleteOrder();
                        }
                      }}
                      className="mt-4 cursor-pointer md:mt-1 float-right rounded-md border border-transparent bg-[#e64444] px-2 py-1 text-sm text-gray-800 shadow-sm hover:opacity-85 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                    >
                      {t("Delete Order")}
                    </button>
                  </a>
                </p>
              </div>
            </section>
          </form>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </main>
      <Dialog
        show={!!displayPaymentText}
        onClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDisplayPaymentText("");
          return false;
        }}
        classes="w-96 text-gray-800"
      >
        <div className="flex flex-col items-center text-">
          <span>{displayPaymentText}</span>
          <RingLoader color="#209c95"/>
          <div className="flex px-10 mt-4">
            <Button
              classes="cursor-pointer w-24"
              onClick={() => setDisplayPaymentText("")}
              text={t("Cancel")}
            />
          </div>
        </div>
      </Dialog>
      <AddressSelection
        currentAddress={order?.address ? order?.address.addressLine1 : null}
        show={displayAddresses}
        onClose={() => {
          setDisplayAddresses(false);
        }}
      />
    </div>
  );
}
